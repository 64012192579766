import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { css, default as styled } from 'styled-components'

import { ETShapeIcon } from 'components/icons/Shapes'

const Layout = styled.div``
export default Layout

export const Main = styled.main`
  grid-template-columns: auto minmax(min-content, 860px) auto;
  grid-gap: 0;
`

export const Header = styled.header`
  grid-column: 2 / -2;
  grid-row: 1;
`
export const MainWithMenu = ({ children, ...passThroughProps }) => (
  <Main {...passThroughProps}>
    <div className="w-3 sm:w-20 md:w-32" style={{ gridColumn: 1, gridRow: 1 }} />
    <div className="w-3 sm:w-20 md:w-32" style={{ gridColumn: 3, gridRow: 1 }}>
      <ETShapeIcon className="z-30 hidden mt-6 -ml-6 w-8 fixed text-primary-2 fill-current" />
    </div>
    {children}
  </Main>
)

export const Section = styled.section`
  grid-column: 2 / -2;
  grid-row: ${props => props.gridRow};
`

const DecoType = {
  '1-top': {
    image: 'sec1-top-separator.svg',
    height: 100,
    adjustHeight: -1,
  },
  '1-bottom': {
    image: 'sec1-bottom-separator.svg',
    height: 96,
    adjustHeight: -1,
  },
  '2-top': {
    image: 'sec2-top-separator.svg',
    height: 157,
    adjustHeight: -1,
  },
  '2-bottom': {
    image: 'sec2-bottom-separator.svg',
    height: 102,
    adjustHeight: -8,
  },
  '3-top': {
    image: 'sec3-top-separator.svg',
    height: 97,
    adjustHeight: -1,
  },
  '3-bottom': {
    image: 'sec3-bottom-separator.svg',
    height: 92,
    adjustHeight: -1,
  },
}

const decoTypeTop = props => {
  return DecoType[(props.decoType || '1') + '-' + (props.inner ? `bottom` : `top`)]
}
const decoTypeBottom = props => {
  return DecoType[(props.decoType || '1') + '-' + (props.inner ? `top` : `bottom`)]
}

export const bgImageFn = props =>
  props.image &&
  css`
    background-image: url(/img/${props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `

export const pseudoBefore = (type, inner) => css`
  &::before {
    content: '';
    display: block;
    height: ${type.height}px;
    width: 100%;
    margin-top: ${inner ? type.adjustHeight : -(type.height + type.adjustHeight)}px;

    ${() => bgImageFn(type)}
  }
`

export const pseudoAfter = (type, inner) => css`
  position: relative;

  &::after {
    content: '';
    display: block;
    height: ${type.height}px;
    width: 100%;
    position: absolute;
    bottom: ${inner ? type.adjustHeight : -(type.height + type.adjustHeight)}px;

    ${() => bgImageFn(type)}
  }
`

// add support for gridRow property
export const withGrid = Component => {
  return class extends React.Component {
    render() {
      const { gridColumn, gridRow, ...passThroughProps } = { gridColumn: 1, gridRow: 1, ...this.props }
      return <Component style={{ gridColumn: `${gridColumn} / -${gridColumn}`, gridRow }} {...passThroughProps} />
    }
  }
}

export const Deco = styled.div`
  grid-column: 1 / -1;
  grid-row: ${props => props.gridRow};

  ${props => props.before && pseudoBefore(decoTypeTop(props), props.inner)}
  ${props => props.after && pseudoAfter(decoTypeBottom(props), props.inner)}
`

const herosQuery = graphql`
  query MyQuery {
    allFile(filter: { relativeDirectory: { eq: "hero" }, extension: { in: ["png", "jpg"] } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 90, maxWidth: 2048) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const BackgroundSection = ({ image, ...props }) => {
  const data = useStaticQuery(herosQuery)
  const fluid = data.allFile.nodes.filter(node => node.name === image)[0].childImageSharp.fluid
  return <BackgroundImage fluid={fluid} {...props} />
}

export const StyledBackgroundSection = styled(BackgroundSection)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: ${props => (props.bgBottom ? 'bottom' : 'center')};
  background-attachment: scroll;
`

export const BackgroundWithGrid = withGrid(StyledBackgroundSection)
