import React from 'react'

export const SocialEmailIcon = props => (
  <svg viewBox="0 0 65.875 64.953" {...props}>
    <defs>
      <filter id="filter-blur" x="0" y="0" width="65.875" height="64.953" filterUnits="userSpaceOnUse">
        <feOffset in="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="translate(-1075.29 -3445.14)">
      <rect width="48" height="48" transform="translate(1084 3453)" fill="none" />
      <g transform="matrix(1, 0, 0, 1, 1075.29, 3445.14)" filter="url(#filter-blur)">
        <path d="M24.385.106C11.827,1.7,0,9.031,0,20.777S9.185,42.1,18.721,43.7,43.7,35.41,42.89,25.343,36.942-1.491,24.385.106Z" transform="matrix(0.07, -1, 1, 0.07, 9.54, 52.36)" stroke="rgba(0,0,0,0)" strokeWidth="1" />
      </g>
      <path d="M822.421,421.98l-18.374,0,9.187,9.052Z" transform="translate(294.633 3047.314)" fill="#fff" />
      <path
        d="M812.987,434.128c-.031.024-.059.051-.092.074a1.213,1.213,0,0,1-.149.078c-.03.015-.058.035-.09.048a1.332,1.332,0,0,1-.184.055c-.023.007-.046.016-.071.022a1.4,1.4,0,0,1-.264.025,1.379,1.379,0,0,1-.263-.025c-.029-.007-.055-.017-.084-.025a1.426,1.426,0,0,1-.172-.051c-.034-.014-.065-.035-.1-.051a1.474,1.474,0,0,1-.141-.075c-.033-.022-.062-.051-.094-.076a1.338,1.338,0,0,1-.116-.093l-11.86-11.685v16.291l25.657,0V422.347l-11.861,11.686A1.405,1.405,0,0,1,812.987,434.128Z"
        transform="translate(295.729 3047.229)"
        fill="#fff"
      />
    </g>
  </svg>
)

export const SocialFacebookIcon = props => (
  <svg viewBox="0 0 61.94 63.525" {...props}>
    <defs>
      <filter id="Path_3094" x="0" y="0" width="61.94" height="63.525" filterUnits="userSpaceOnUse">
        <feOffset in="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_241" data-name="Group 241" transform="translate(-891.5 -3445.968)">
      <rect id="Rectangle_88" data-name="Rectangle 88" width="48" height="48" transform="translate(898 3453)" fill="none" />
      <g id="social_fb" data-name="social fb" transform="translate(45.73 131.484)">
        <g transform="matrix(1, 0, 0, 1, 845.77, 3314.48)" filter="url(#Path_3094)">
          <path
            id="Path_3094-2"
            data-name="Path 3094"
            d="M808.392,112.815C795.83,114.435,784,121.862,784,133.77s9.188,21.62,18.726,23.239S827.713,148.6,826.9,138.4,820.953,111.2,808.392,112.815Z"
            transform="translate(-774.5 -103.2)"
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
          />
        </g>
        <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(859.534 3328.387)" fill="#fff" stroke="#707070" strokeWidth="1">
          <ellipse cx="17.926" cy="17.926" rx="17.926" ry="17.926" stroke="none" />
          <ellipse cx="17.926" cy="17.926" rx="17.426" ry="17.426" fill="none" />
        </g>
        <path
          id="Path_4040"
          data-name="Path 4040"
          d="M995.829,783.971a19.829,19.829,0,1,0,19.829,19.829A19.829,19.829,0,0,0,995.829,783.971Zm7.073,9.305-.725,2.82c-.04.162-.2.4-.364.4a10.153,10.153,0,0,0-2.821-.687c-2.176,0-2.256,1.33-2.256,3.264v1.651h4.594c.363,0,.685.082.685.4a1.418,1.418,0,0,1-.081.4l-.564,2.377c-.121.524-.362.566-.765.566h-3.869V814.5c0,.726-.16.847-.846.847h-3.425c-.685,0-.846-.121-.846-.847V804.479h-2.861c-.685,0-.846-.121-.846-.848v-2.054c0-.725.162-.848.846-.848h2.861v-1.651c0-4.432,1.612-7.615,6.447-7.615,1.813,0,4.916.644,4.916,1.45A1.173,1.173,0,0,1,1002.9,793.276Z"
          transform="translate(-118.461 2542.659)"
        />
      </g>
    </g>
  </svg>
)

export const SocialInstagramIcon = props => (
  <svg viewBox="0 0 65.442 64.245" {...props}>
    <defs>
      <filter id="Path_4041" x="0" y="0" width="65.442" height="64.245" filterUnits="userSpaceOnUse">
        <feOffset in="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="social-insta" transform="translate(-955.523 -3445.593)">
      <rect width="48" height="48" transform="translate(964 3453)" fill="none" />
      <g transform="matrix(1, 0, 0, 1, 955.52, 3445.59)" filter="url(#Path_4041)">
        <path d="M24.392,44.048C11.83,42.44,0,35.066,0,23.244S9.188,1.78,18.726.172,43.713,8.517,42.9,18.648,36.953,45.656,24.392,44.048Z" transform="translate(11.82 54.72) rotate(-93)" stroke="rgba(0,0,0,0)" strokeWidth="1" />
      </g>
      <g id="Ellipse_23" data-name="Ellipse 23" transform="translate(982.366 3471.308)" fill="none" stroke="#fff" strokeWidth="3">
        <ellipse cx="6.017" cy="6.017" rx="6.017" ry="6.017" stroke="none" />
        <ellipse cx="6.017" cy="6.017" rx="4.517" ry="4.517" fill="none" />
      </g>
      <g id="Rectangle_47" data-name="Rectangle 47" transform="translate(976.349 3465.291)" fill="none" stroke="#fff" strokeWidth="3">
        <rect width="24.068" height="24.069" rx="7" stroke="none" />
        <rect x="1.5" y="1.5" width="21.068" height="21.069" rx="5.5" fill="none" />
      </g>
      <g id="Ellipse_24" data-name="Ellipse 24" transform="translate(993.524 3469.293)" fill="none" stroke="#fff" strokeWidth="3">
        <ellipse cx="1.445" cy="1.445" rx="1.445" ry="1.445" stroke="none" />
        <ellipse cx="1.445" cy="1.445" rx="0.055" ry="0.055" fill="none" />
      </g>
    </g>
  </svg>
)

export const SocialYouTubeIcon = props => (
  <svg viewBox="0 0 64.262 65.762" {...props}>
    <defs>
      <filter id="filter-blur-yt" x="0" y="0" width="64.262" height="65.762" filterUnits="userSpaceOnUse">
        <feOffset in="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="social-youtube" transform="translate(-844.805 -2827.567)">
      <g transform="matrix(1, 0, 0, 1, 844.81, 2827.57)" filter="url(#filter-blur-yt)">
        <path d="M24.392,44.367C11.83,42.747,0,35.32,0,23.412S9.188,1.793,18.726.173,43.713,8.578,42.9,18.783,36.953,45.987,24.392,44.367Z" transform="translate(9.53 11.82) rotate(-3)" stroke="none" />
      </g>
      <rect width="22" height="16" rx="5" transform="translate(866.193 2852.273)" fill="#fff" />
      <path d="M4,0,8,7.8H0Z" transform="translate(881.489 2856.273) rotate(90)" fill="#1d1d1d" />
    </g>
  </svg>
)

export const SocialLinkedInIcon = props => (
  <svg viewBox="0 0 64.262 65.762" {...props}>
    <defs>
      <filter id="filter-blur-In" x="0" y="0" width="64.262" height="65.762" filterUnits="userSpaceOnUse">
        <feOffset in="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="social-ln" transform="translate(-1019.612 -3443.81)">
      <rect id="Rectangle_90" data-name="Rectangle 90" width="48" height="48" transform="translate(1028 3453)" fill="none" />
      <g transform="matrix(1, 0, 0, 1, 1019.61, 3443.81)" filter="url(#filter-blur-In)">
        <path
          id="Path_4042-2"
          data-name="Path 4042"
          d="M24.392,44.367C11.83,42.747,0,35.32,0,23.412S9.188,1.793,18.726.173,43.713,8.578,42.9,18.783,36.953,45.987,24.392,44.367Z"
          transform="translate(9.53 11.82) rotate(-3)"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
      </g>
      <text id="in" transform="translate(1051.73 3486.484)" fill="#fff" fontSize="32" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="-0.025em">
        <tspan x="-13.819" y="0">
          in
        </tspan>
      </text>
    </g>
  </svg>
)
