import React from 'react'

export const Circle = (props) => {
  const { strokeWidth, ...passThroughProps } = { strokeWidth: 0, ...props }
  const radius = 5
  const length = radius + strokeWidth
  const viewBox = `0 0 ${2 * length} ${2 * length}`

  return (
    <svg viewBox={viewBox} {...passThroughProps}>
      <circle cx={length} cy={length} r={radius} strokeWidth={strokeWidth}/>
    </svg>
  )
}

export const ETShapeIcon = props => {
  const { rotate, ...passThroughProps } = props
  const transform = `rotate(${rotate || 0})`

  return (<svg viewBox="-34 -34 68 68" {...passThroughProps}>
      <g id="et-bg" transform={transform}>
        <path
          d="M820.2,112.867c-18.645,2.4-36.2,13.429-36.2,31.1s13.637,32.089,27.795,34.493,37.086-12.476,35.884-27.622S838.849,110.463,820.2,112.867Z"
          transform="translate(-591 1734.03) translate(-199 -1846.738) translate(-26 -33)"/>
      </g>
    </svg>
  )
}

export const LinkArrowIcon = props => (
  <svg viewBox="0 0 9.5 7.5" {...props}>
    <g transform="translate(-92.75 -504.75)">
      <line x2="8" transform="translate(93.5 508.5)" strokeLinecap="round" strokeWidth="1.5" />
      <path
        d="M-509,3l-3,3Zm-3-3,3,3Z"
        transform="translate(610.5 505.5)"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)
