import React from 'react'

export const LogoIcon = props => (
  <svg viewBox="0 0 29.944 35.29" {...props}>
    <path
      d="M1012.135,880.1l-.076-.447a.966.966,0,0,0-.6-.727.8.8,0,0,1-.162-.083l-3.953-2.786a.692.692,0,0,0-.216-.1l-6-1.675c-.147-.059-.913.114-1.068.079l-8.405-1.376a.746.746,0,0,1-.109-.026l-5.259-1.7a1.038,1.038,0,0,0-1.042.26l-1.851,1.9a.546.546,0,0,0-.152.294l-1.037,6.4a.612.612,0,0,0,.658.66h28.614A.611.611,0,0,0,1012.135,880.1Zm-24.482-10.788a.493.493,0,0,0,.377.279l5.212.925a.565.565,0,0,1,.2.077l.809.507a.559.559,0,0,0,.22.079l12.4,1.767a.574.574,0,0,0,.482-.149l1.411-1.4a.388.388,0,0,0,.113-.367l-1.041-4.37a.448.448,0,0,0-.263-.3l-2.027-.9a.6.6,0,0,0-.358-.038l-4.477.89a.583.583,0,0,1-.082.011l-6.468.381-.036,0-6.574.773a.44.44,0,0,0-.418.574Zm5.264-4.46,5.195-.478a1.126,1.126,0,0,0,.156-.026l2.151-.524a.978.978,0,0,1,.108-.021l2.424-.333c.31-.042.564-.191.6-.353l.191-.775a.2.2,0,0,0-.112-.219l-.986-.609a.123.123,0,0,0-.07-.014l-5.96.553a1.118,1.118,0,0,1-.12,0l-4.811-.121h-.065l-3.643.145a.961.961,0,0,0-.675.267l-.712.9a.23.23,0,0,0-.055.126l-.156,2.016c-.015.2.3.319.679.267l5.811-.8Zm-6.432-13.419,2.929,1.434a2.959,2.959,0,0,0,1.54.2l13.593-1.361a4.3,4.3,0,0,0,1.715-.541l1.166-.689c.805-.475,1.083-1.109.682-1.556l-2.631-2.93a1.928,1.928,0,0,0-1.369-.473c-.956-.027-2.381-.046-3.253-.045a4.45,4.45,0,0,0-1.426.246l-4.742,1.642q-.155.053-.317.1l-6.538,1.732A2.6,2.6,0,0,0,986,850.46l-.007.021A.754.754,0,0,0,986.484,851.429Zm9.548,2.209a.74.74,0,0,0-.192.033l-3.7,1.164a.543.543,0,0,0-.37.748.7.7,0,0,0,.671.377l6.389-.291a.751.751,0,0,0,.2-.037l2.428-.812a.576.576,0,0,0,.4-.437l.044-.258a.641.641,0,0,0-.709-.685Zm-11.044,6.705,8.92.006a4.045,4.045,0,0,0,.455-.026l5.082-.025a14.145,14.145,0,0,0,3.741-.524c2.084-.583,4.828-1.475,4.659-1.7l-.174-.237a1.432,1.432,0,0,0-1.053-.3l-4.594-.326c-.153,0-1.709-.272-1.868-.254l-3.941.36c-.444.04-.891.049-1.337.058a14.128,14.128,0,0,0-2.716.318l-6.791,1.473a2.29,2.29,0,0,0-1.452.57C983.678,860.067,984.159,860.344,984.989,860.344Z"
      transform="translate(-982.198 -845.47)"
      fill="fillCurrent"
    />
  </svg>
)

export const LogoVerticalIcon = props => (
  <svg viewBox="0 0 257.182 160.108" {...props}>
    <g transform="translate(-511 -1515.558)">
      <path
        d="M733.9,4308.614l-9.252,14.678a15.753,15.753,0,0,0-1.934,8.035l-.531,72.686a22.876,22.876,0,0,0,1.885,9.381l2.982,6.563c2.056,4.527,5.252,6.359,7.845,4.492l17-12.252a10.256,10.256,0,0,0,3.281-6.975c.686-5.041,1.6-12.57,2.089-17.184a23.722,23.722,0,0,0-.491-7.686l-5.989-26.021q-.192-.847-.348-1.732l-5.446-35.574a13.836,13.836,0,0,0-5.666-10.426l-.107-.049a4.014,4.014,0,0,0-5.291,2.049l0,.012Z"
        transform="translate(-56.103 -2753.225)"
        fill="#fff"
      />
      <path
        d="M789.853,4367.3a3.972,3.972,0,0,0,.08-1.033l-1.2-20.6a2.891,2.891,0,0,0-2.856-2.924,3.113,3.113,0,0,0-.522.041,3.728,3.728,0,0,0-2.818,2.973l-6.805,33.344a3.965,3.965,0,0,0-.069,1.08l1.033,13.582a3.068,3.068,0,0,0,1.734,2.635l1.274.561a3.411,3.411,0,0,0,4.314-2.158,3.476,3.476,0,0,0,.143-.609Z"
        transform="translate(-21.757 -2729.761)"
        fill="#00edae"
      />
      <path
        d="M672.468,4420.52l-.138-47.463a22.112,22.112,0,0,0-.148-2.422l-.232-27.039a75.3,75.3,0,0,0-2.859-19.9c-3.141-11.076-7.942-25.66-9.135-24.758l-1.258.932a7.622,7.622,0,0,0-1.575,5.607l-1.647,24.451c0,.814-1.414,9.1-1.315,9.943l1.992,20.965c.22,2.361.278,4.738.333,7.111a75.312,75.312,0,0,0,1.744,14.445L666.2,4418.5a12.184,12.184,0,0,0,3.061,7.715c1.746,1.283,3.21-1.281,3.194-5.7Z"
        transform="translate(-99.788 -2757.916)"
        fill="#00edae"
      />
      <path
        d="M774.628,4378.185l.424-27.754a6.01,6.01,0,0,0-.049-.842l-1.55-11.674a5.08,5.08,0,0,1-.049-.586l-.384-13.014c-.046-1.664-.69-3.092-1.527-3.375l-3.992-1.449a1.06,1.06,0,0,0-1.222.467l-3.782,4.871a.645.645,0,0,0-.114.363l-.461,31.846a5.844,5.844,0,0,1-.069.635l-3.373,25.383-.038.344-1.3,19.355a5.1,5.1,0,0,0,1.03,3.723l4.357,4.279a1.208,1.208,0,0,0,.634.361l10.578,1.971c1.049.193,1.857-1.406,1.8-3.439l-.929-31.2Z"
        transform="translate(-33.673 -2744.722)"
        fill="#fff"
      />
      <path
        d="M631.8,4313.628a2.629,2.629,0,0,0-1.146,2.219L630.194,4344a3.03,3.03,0,0,1-.236,1.117l-1.98,4.68a2.975,2.975,0,0,0-.229,1.223l1.191,66.633a3.057,3.057,0,0,0,1.191,2.408l8.546,6.23a2.068,2.068,0,0,0,2.023.283l22.078-9.16a2.384,2.384,0,0,0,1.355-1.635l3.015-11.41a3.188,3.188,0,0,0-.1-1.914l-8.457-22.768a2.813,2.813,0,0,1-.126-.42L651,4345.616l-.031-.191-9.614-33.883a2.341,2.341,0,0,0-2.688-1.932,2.262,2.262,0,0,0-.68.221Z"
        transform="translate(-116.748 -2751.059)"
        fill="#00edae"
      />
      <path
        d="M714.975,4283.131l-2.34.584a5.147,5.147,0,0,0-3.615,3.479,4.175,4.175,0,0,1-.374.893l-13.177,22.1a3.679,3.679,0,0,0-.442,1.188l-6.454,32.514c-.253.8.975,4.795.852,5.635l-3.89,45.146a3.943,3.943,0,0,1-.094.592l-6.885,28.592a5.526,5.526,0,0,0,1.8,5.422l10.831,9.047a2.879,2.879,0,0,0,1.623.689l34.375,2.906a3.258,3.258,0,0,0,3.273-3.24,3.3,3.3,0,0,0-.038-.521l-11.609-151.811a3.247,3.247,0,0,0-3.23-3.268A3.17,3.17,0,0,0,714.975,4283.131Z"
        transform="translate(-164.234 -2754.12) rotate(-1)"
        fill="#fff"
      />
    </g>
  </svg>
)

export const LogoHorizontalIcon = props => (
  <svg viewBox="0 0 135.518 64.118" {...props}>
    <g transform="translate(-494.802 -2439.882)">
      <path
        d="M12.625.018l-.808.2a1.778,1.778,0,0,0-1.248,1.2,1.441,1.441,0,0,1-.129.308L5.888,9.363a1.272,1.272,0,0,0-.153.41L3.507,21c-.087.277.337,1.656.294,1.946L2.458,38.541a1.361,1.361,0,0,1-.032.2L.047,48.62A1.909,1.909,0,0,0,.67,50.492L4.41,53.617a1,1,0,0,0,.56.238l11.872,1a1.125,1.125,0,0,0,1.13-1.119,1.141,1.141,0,0,0-.013-.18L13.951,1.129A1.121,1.121,0,0,0,12.835,0,1.1,1.1,0,0,0,12.625.018Z"
        transform="translate(494.802 2501.129) rotate(-87)" fill="#fff"/>
      <path
        d="M4.047.831.852,5.9A5.44,5.44,0,0,0,.184,8.676L0,33.779a7.9,7.9,0,0,0,.651,3.24l1.03,2.267c.71,1.564,1.814,2.2,2.709,1.551l5.87-4.231A3.542,3.542,0,0,0,11.395,34.2c.237-1.741.552-4.341.721-5.935a8.192,8.192,0,0,0-.17-2.654L9.878,16.621q-.066-.292-.12-.6L7.877,3.736A4.779,4.779,0,0,0,5.92.135L5.883.118A1.386,1.386,0,0,0,4.056.826v0Z"
        transform="translate(587.296 2457.386) rotate(93)" fill="#fff"/>
      <path
        d="M4.9,8.482a1.37,1.37,0,0,0,.028-.357L4.518,1.01A1,1,0,0,0,3.531,0a1.067,1.067,0,0,0-.18.014,1.287,1.287,0,0,0-.973,1.027L.027,12.557A1.369,1.369,0,0,0,0,12.93L.36,17.621a1.06,1.06,0,0,0,.6.91l.44.194a1.178,1.178,0,0,0,1.49-.745,1.2,1.2,0,0,0,.049-.21Z"
        transform="translate(611.718 2447.381) rotate(-98)" fill="#00edae"/>
      <path
        d="M6.325,42,6.277,25.611a7.635,7.635,0,0,0-.051-.836l-.08-9.339a26.007,26.007,0,0,0-.987-6.872C4.073,4.739,2.416-.3,2,.014L1.569.336a2.632,2.632,0,0,0-.544,1.937L.456,10.717C.457,11-.032,13.859,0,14.151L.69,21.392c.076.815.1,1.636.115,2.456a26.011,26.011,0,0,0,.6,4.989l2.752,12.47a4.208,4.208,0,0,0,1.057,2.665c.6.443,1.109-.443,1.1-1.968Z"
        transform="translate(566.844 2451.227) rotate(-72)" fill="#00edae"/>
      <path
        d="M6.053,20.287,6.2,10.7a2.076,2.076,0,0,0-.017-.291L5.647,6.38a1.754,1.754,0,0,1-.017-.2L5.5,1.683C5.481,1.108,5.259.615,4.969.517L3.591.016a.366.366,0,0,0-.422.161L1.862,1.86a.223.223,0,0,0-.039.125l-.159,11a2.019,2.019,0,0,1-.024.219L.475,21.97l-.013.119-.45,6.685a1.762,1.762,0,0,0,.356,1.286l1.5,1.478a.417.417,0,0,0,.219.125l3.653.681c.362.067.641-.486.621-1.188L6.045,20.38Z"
        transform="translate(621.771 2448.803) rotate(99)" fill="#fff"/>
      <path
        d="M1.4,1.4a.908.908,0,0,0-.4.766L.845,11.89a1.046,1.046,0,0,1-.082.386L.079,13.892A1.028,1.028,0,0,0,0,14.314L.412,37.327a1.056,1.056,0,0,0,.411.832l2.952,2.152a.714.714,0,0,0,.7.1L12.1,37.245a.823.823,0,0,0,.468-.565l1.041-3.941a1.1,1.1,0,0,0-.035-.661l-2.921-7.863a.97.97,0,0,1-.044-.145L8.029,12.446l-.011-.066L4.7.678A.808.808,0,0,0,3.77.011a.785.785,0,0,0-.235.076Z"
        transform="matrix(0.035, -0.999, 0.999, 0.035, 530.017, 2481.963)" fill="#00edae"/>
    </g>
  </svg>
)

export const LogoSpreadIcon = props => (
  <svg viewBox="0 0 69 64.868" {...props}>
    <g transform="translate(-378.381 -3744.1)">
      <path
        d="M469.088,3825.475l.134.524a1.153,1.153,0,0,0,.784.806.941.941,0,0,1,.2.083l4.972,2.928a.831.831,0,0,0,.267.1l7.3,1.407c.18.056,1.075-.225,1.262-.2l10.129.817a.834.834,0,0,1,.132.02l6.42,1.509a1.239,1.239,0,0,0,1.214-.411l2.016-2.44a.651.651,0,0,0,.152-.365l.609-7.711a.73.73,0,0,0-.73-.73.741.741,0,0,0-.116.009l-34.026,2.79a.729.729,0,0,0-.729.729A.705.705,0,0,0,469.088,3825.475Z"
        transform="translate(-57.297 -49.094)"
      />
      <path
        d="M455.689,3768.286l3.159-2.272a3.533,3.533,0,0,0,1.078-1.507l6.228-15.062a5.132,5.132,0,0,0,.4-2.109l-.068-1.614c-.046-1.114-.556-1.764-1.252-1.594l-4.564,1.116a2.3,2.3,0,0,0-1.269,1.173c-.567.99-1.39,2.478-1.881,3.4a5.312,5.312,0,0,0-.545,1.638l-.946,5.913q-.031.193-.073.39l-1.863,7.852a3.1,3.1,0,0,0,.3,2.643l.018.019a.9.9,0,0,0,1.272.02v0Z"
        transform="translate(-47.726)"
      />
      <path
        d="M423.915,3794.537a.888.888,0,0,0-.143-.184l-3.307-3.237a.648.648,0,0,0-.915-.054.609.609,0,0,0-.081.086.835.835,0,0,0-.019.918l3.9,6.558a.894.894,0,0,0,.151.19l2.221,2.1a.688.688,0,0,0,.685.175l.3-.1a.764.764,0,0,0,.321-1.131Z"
        transform="translate(-25.865 -29.565)"
      />
      <path
        d="M403.367,3866.934l6.325-8.56a4.784,4.784,0,0,0,.3-.455l3.576-4.9a16.882,16.882,0,0,0,2.147-3.962c.917-2.414,2-5.68,1.668-5.677h-.351a1.707,1.707,0,0,0-1.034.8l-3.568,4.179c-.108.147-1.472,1.448-1.567,1.613l-2.446,4.039c-.276.455-.584.891-.891,1.325a16.84,16.84,0,0,0-1.619,2.833l-3.4,7.563a2.733,2.733,0,0,0-.482,1.8c.143.464.749.2,1.337-.6Z"
        transform="translate(-14.932 -62.722)"
      />
      <path
        d="M386.21,3835.639l6.221-.2a1.339,1.339,0,0,0,.187-.02l2.6-.469a1.274,1.274,0,0,1,.13-.017l2.911-.222c.372-.028.685-.187.74-.377l.283-.909a.238.238,0,0,0-.117-.269l-1.13-.8a.146.146,0,0,0-.083-.021l-7.138.229a1.329,1.329,0,0,1-.143-.008l-5.721-.49-.077,0-4.349-.09a1.147,1.147,0,0,0-.823.27l-.912,1.021a.274.274,0,0,0-.075.146l-.331,2.39c-.032.237.334.4.79.367l6.978-.535Z"
        transform="translate(0 -55.516)"
      />
      <path
        d="M455.414,3851.354a.589.589,0,0,0-.163.535l1.044,6.229a.677.677,0,0,1-.007.255l-.247,1.113a.672.672,0,0,0,0,.279l2.972,14.646a.686.686,0,0,0,.361.482l2.138,1.026a.462.462,0,0,0,.458-.02l4.5-2.917a.534.534,0,0,0,.232-.416l.2-2.638a.714.714,0,0,0-.1-.418l-2.791-4.677a.7.7,0,0,1-.045-.088l-3.014-7.118-.015-.041-3.5-7.081a.525.525,0,0,0-.671-.317.534.534,0,0,0-.141.077Z"
        transform="translate(-48.556 -67.003)"
      />
    </g>
  </svg>
)

const origin = { rotate: 0, scale: 1, tx: 0, ty: 0 }
export const StoneStyles = [
  {
    stone1: origin,
    stone2: origin,
    stone3: origin,
    stone4: origin,
    stone5: origin,
    stone6: origin
  },
  {
    stone1: { rotate: -86, scale: 0.745, tx: -46, ty: 35 },
    stone2: { rotate: -88, scale: 0.745, tx: 100, ty: -24 },
    stone3: { rotate: 99, scale: 0.745, tx: 16, ty: -86 },
    stone4: { rotate: -72, scale: 0.745, tx: 149, ty: -56 },
    stone5: { rotate: -98, scale: 0.745, tx: 21, ty: -117 },
    stone6: { rotate: 93, scale: 0.745, tx: -18, ty: -48 },
  },
  {
    stone1: { rotate: 60, scale: 0.69, tx: -20, ty: 49 },
    stone2: { rotate: 2, scale: 0.69, tx: 127, ty: 52 },
    stone3: { rotate: 99, scale: 0.69, tx: -35, ty: 4 },
    stone4: { rotate: 5, scale: 0.69, tx: 83, ty: -44 },
    stone5: { rotate: -127, scale: 0.69, tx: -87, ty: -45 },
    stone6: { rotate: 121, scale: 0.69, tx: -79, ty: -22 },
  },
  {
    stone1: { rotate: 1, scale: 0.85, tx: 108, ty: 13 },
    stone2: { rotate: 2, scale: 0.94, tx: 108, ty: 0 },
    stone3: { rotate: 82, scale: 0.84, tx: -54, ty: -67 },
    stone4: { rotate: 5, scale: 0.89, tx: -19, ty: 8 },
    stone5: { rotate: -95, scale: 0.7, tx: -88, ty: -98 },
    stone6: { rotate: 90, scale: 0.69, tx: -92, ty: -60 },
  }
]

const Stone = (props) => {
  const { rotate, scale, tx, ty, className, d, fill, ...passThroughProps } = props
  return (
    <g className={className} style={{ transform: `translate(${tx}px, ${ty}px)`}} {...passThroughProps}>
      <path
        className={className}
        style={{ transform: `rotate(${rotate}deg) scale(${scale})` }}
        d={d}
        fill={fill}/>
    </g>
  )
}

export const Stones = (props) => {
  const { stoneClasses, styles, ...passThroughProps } = props
  return (
    <svg viewBox="0 0 258 161" {...passThroughProps}>
      <Stone
        id="stone1"
        d="M114.383 0.322587L112.054 0.94732C111.208 1.18544 110.438 1.6362 109.816 2.2561C109.194 2.876 108.741 3.64443 108.5 4.48888C108.416 4.80156 108.295 5.10346 108.142 5.38834L95.3522 27.715C95.1437 28.0862 95.0012 28.4907 94.931 28.9106L89.0455 61.5324C88.8065 62.3367 90.104 66.3094 89.9957 67.1515L86.8942 112.358C86.8814 112.558 86.8534 112.757 86.8105 112.952L80.4256 141.66C80.2204 142.646 80.2884 143.668 80.6222 144.618C80.9561 145.568 81.5431 146.409 82.32 147.05L93.3072 155.906C93.7696 156.292 94.3414 156.523 94.942 156.567L129.362 158.872C130.226 158.861 131.05 158.508 131.653 157.89C132.256 157.272 132.589 156.44 132.578 155.576C132.576 155.402 132.561 155.228 132.531 155.056L118.275 3.47048C118.27 3.04376 118.181 2.62213 118.013 2.22973C117.846 1.83734 117.602 1.48172 117.297 1.18348C116.992 0.88525 116.631 0.650069 116.235 0.491472C115.838 0.332874 115.415 0.254061 114.988 0.259284C114.785 0.261031 114.582 0.282053 114.383 0.322587Z"
        fill="#ffffff"
        className={stoneClasses}
        {...styles.stone1}/>
      <Stone
        id="stone2"
        d="M4.052 47.0109C3.69197 47.2567 3.39871 47.5884 3.19867 47.9757C2.99862 48.3631 2.89806 48.7942 2.906 49.2301L2.44598 77.383C2.4388 77.7669 2.35875 78.1461 2.21002 78.5001L0.230038 83.1798C0.068354 83.5668 -0.00968978 83.9837 0.00103376 84.403L1.19201 151.036C1.19406 151.501 1.30244 151.96 1.50885 152.378C1.71525 152.795 2.01421 153.16 2.38299 153.444L10.929 159.674C11.2129 159.892 11.5485 160.033 11.9031 160.083C12.2577 160.132 12.6192 160.089 12.952 159.957L35.03 150.797C35.3669 150.648 35.6645 150.423 35.8996 150.139C36.1347 149.855 36.3008 149.52 36.385 149.162L39.4 137.752C39.5638 137.119 39.5289 136.451 39.3 135.838L30.843 113.07C30.7901 112.933 30.748 112.793 30.717 112.65L23.252 78.9992L23.221 78.8078L13.607 44.9249C13.5067 44.3123 13.1672 43.7648 12.6631 43.4025C12.159 43.0402 11.5316 42.8926 10.919 42.9928C10.6821 43.03 10.4525 43.1048 10.239 43.214L4.052 47.0109Z"
        fill="#00edae"
        className={stoneClasses}
        {...styles.stone2}/>
      <Stone
        id="stone3"
        d="M229.955 117.905L230.379 90.1511C230.382 89.8696 230.366 89.588 230.33 89.3088L228.78 77.635C228.752 77.4408 228.736 77.2452 228.731 77.0491L228.347 64.0349C228.301 62.3709 227.657 60.9429 226.82 60.6599L222.828 59.2112C222.601 59.1411 222.358 59.149 222.136 59.2336C221.915 59.3183 221.728 59.4746 221.606 59.678L217.824 64.5491C217.75 64.6558 217.711 64.7821 217.71 64.9119L217.249 96.758C217.238 96.9708 217.215 97.1826 217.18 97.3928L213.807 122.776L213.769 123.12L212.469 142.475C212.311 143.803 212.681 145.14 213.499 146.198L217.856 150.477C218.025 150.66 218.247 150.786 218.49 150.838L229.068 152.809C230.117 153.002 230.925 151.403 230.868 149.37L229.939 118.17L229.955 117.905Z"
        fill="#ffffff"
        className={stoneClasses}
        {...styles.stone3}/>
      <Stone
        id="stone4"
        d="M61.68 147.046L61.542 99.583C61.5371 98.7735 61.4876 97.9646 61.394 97.1606L61.162 70.122C61.1028 63.3907 60.1412 56.6974 58.303 50.2216C55.162 39.1456 50.361 24.5618 49.168 25.4638L47.91 26.396C46.6635 27.9828 46.0971 29.9991 46.335 32.0029L44.688 56.4541C44.688 57.2681 43.274 65.5539 43.373 66.3969L45.365 87.3618C45.585 89.7228 45.643 92.0996 45.698 94.4726C45.8118 99.3326 46.3958 104.171 47.442 108.918L55.412 145.026C55.498 147.877 56.5812 150.607 58.473 152.741C60.219 154.024 61.683 151.46 61.667 147.041L61.68 147.046Z"
        fill="#00edae"
        className={stoneClasses}
        {...styles.stone4}/>
      <Stone
        id="stone5"
        d="M257.096 121.981C257.168 121.642 257.194 121.295 257.176 120.948L255.976 100.348C255.985 99.5817 255.689 98.8432 255.153 98.2949C254.618 97.7465 253.886 97.4335 253.12 97.4242C252.945 97.4232 252.77 97.437 252.598 97.4653C251.889 97.6316 251.245 98.0014 250.744 98.5297C250.243 99.058 249.908 99.7214 249.78 100.438L242.975 133.782C242.903 134.137 242.88 134.501 242.906 134.862L243.939 148.444C243.962 148.999 244.136 149.537 244.441 150.001C244.747 150.465 245.172 150.838 245.673 151.079L246.947 151.64C247.805 151.926 248.742 151.859 249.551 151.455C250.36 151.05 250.975 150.34 251.261 149.482C251.327 149.284 251.375 149.08 251.404 148.873L257.096 121.981Z"
        fill="#00edae"
        className={stoneClasses}
        {...styles.stone5}/>
      <Stone
        id="stone6"
        d="M166.797 39.8308L157.545 54.509C156.197 56.9675 155.529 59.7415 155.611 62.5442L155.08 135.23C155.04 138.455 155.682 141.652 156.965 144.611L159.947 151.174C162.003 155.701 165.199 157.533 167.792 155.666L184.792 143.414C186.751 141.604 187.928 139.102 188.073 136.439C188.759 131.398 189.673 123.869 190.162 119.255C190.418 116.683 190.252 114.087 189.671 111.569L183.682 85.5481C183.554 84.9834 183.438 84.4062 183.334 83.8162L177.888 48.2419C177.776 46.1926 177.209 44.1941 176.229 42.3909C175.249 40.5876 173.881 39.025 172.222 37.8162L172.115 37.7668C171.142 37.3375 170.038 37.3123 169.045 37.6965C168.053 38.0807 167.254 38.8432 166.824 39.8162V39.8279L166.797 39.8308Z"
        fill="#ffffff"
        className={stoneClasses}
        {...styles.stone6}/>
    </svg>
  )
}
