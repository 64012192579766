import React from 'react';

const Tumba = props => (
  <svg viewBox="0 0 138.208 39" {...props}>
    <path
      d="M112.244,30.383c0-5.782,3.669-8.728,8.618-9.451l7-1.056c1.223-.167,2.724-.667,2.724-2.335,0-3.28-3.113-3.447-5.115-3.447-3.892,0-4.559,2.391-4.781,4.114h-7.506c.89-9.062,7.173-10.341,13.232-10.341,4.169,0,11.787,1.279,11.787,8.562V38.222H130.59V34.947A11.9,11.9,0,0,1,121.251,39C116.8,39,112.244,36.5,112.244,30.383Zm13.065-4.948c-2.891.5-5.281,1.224-5.281,4.448a3.4,3.4,0,0,0,3.613,3.558c3.447,0,6.949-2.224,6.949-5.838V23.822C129.812,24.434,128.534,24.934,125.309,25.434ZM90.552,34.5h-.111v3.725H82.824V0h7.784V12.481h.111A9.491,9.491,0,0,1,99.28,7.866c6.5,0,12.287,5,12.287,15.233,0,8.117-3.669,15.9-12.454,15.9C95.89,39,92.442,37.832,90.552,34.5Zm-.278-10.4c0,4.392,1.946,8.507,6.894,8.507,5,0,6.394-5.449,6.394-8.951,0-4.67-1.669-9.062-6.616-9.062C91.608,14.594,90.274,19.375,90.274,24.1Zm-71.217,4V8.645h7.784V26.991c0,4.559,2.668,5.449,5.058,5.449,2.558,0,6-1.446,6-6.727V8.645h7.784v4.336H45.8a8.8,8.8,0,0,1,8.395-5.115c4.281,0,6.227,1.557,8.228,4.726,1-1.445,3.169-4.726,7.895-4.726,5.782,0,10.285,3.392,10.285,9.9v20.46H72.818V20.264c0-4.392-1.946-5.837-4.225-5.837-3.892,0-5.281,2.557-5.281,6.06V38.222H55.528V20.431c0-2.5,0-6-4.67-6-3.669,0-4.837,2.557-4.837,5.782V38.222H38.238v-4.17h-.111C36.347,37.221,33.234,39,29.12,39,23.282,39,19.057,35.664,19.057,28.1ZM12.164,38.555c-6.727,0-7.95-2.558-7.95-6.394V15.1H0V8.645H4.214V0H12V8.645h4.837V15.1H12V30.272c0,1.723.056,2.391,3.225,2.391.5,0,1.056-.055,1.612-.111v5.893c-1.223.055-2.446.111-3.669.111Z"
      fill="fillCurrent"
    />
  </svg>
);

export default Tumba;
