import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { ldJsonHelper } from './ldJson'

// https://developers.google.com/search/docs/advanced/structured-data/job-posting
export const LdJsonJobPosting = ({ title, description, minSalary, maxSalary, publishDate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  const siteUrl = site.siteMetadata.siteUrl || ''

  const datePosted = new Date(publishDate || site.buildTime || new Date())
  const daysValid = 45
  const validThrough = new Date(datePosted)
  validThrough.setDate(datePosted.getDate() + daysValid)

  const jdJsonLogoContent = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: title,
    description: description,
    hiringOrganization: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: site.siteMetadata.title || 'Tumba Solutions',
      slogan: site.siteMetadata.description || 'Pure passion for technology and product growth.',
      sameAs: [
        siteUrl,
        'https://clutch.co/profile/tumba-solutions',
        'https://github.com/TumbaSolutions/',
        'https://www.linkedin.com/company/tumba-solutions-ltd-/',
        'https://www.facebook.com/tumba.solutions',
        'https://www.instagram.com/tumba.solutions',
        'https://twitter.com/TumbaSolutions/',
        'https://www.youtube.com/channel/UCce0nOFno-f5mfEhG9OwiHQ',
      ],
      url: siteUrl,
      logo: siteUrl + '/img/tumbalogo.png',
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'BGN',
      value: {
        '@type': 'QuantitativeValue',
        minValue: minSalary || 4000.0,
        maxValue: maxSalary || 7000.0,
        unitText: 'MONTH',
      },
    },
    applicantLocationRequirements: {
      '@type': 'Country',
      name: 'Bulgaria',
    },
    jobLocationType: 'TELECOMMUTE',
    employmentType: 'FULL_TIME',
    directApply: true,
    industry: 'Software Development',
    datePosted: datePosted,
    validThrough: validThrough,
  }
  return ldJsonHelper(jdJsonLogoContent)
}

LdJsonJobPosting.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
