import { graphql, Link, useStaticQuery } from 'gatsby'
import { LogoIcon, TumbaIcon } from '../icons'
import React from 'react'

export const HeaderLink = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `,
  )

  const { className, ...passThroughProps } = props
  // SVG fill color will not work if text color is no explicitly set in className
  const classes = `relative block ml-6 sm:ml-0 url u-url ${className || 'text-primary-1'}`

  return (
    <span className="vcard h-card">
      <Link to={site.siteMetadata.siteUrl} aria-label="Home" className={classes} {...passThroughProps}>
        <TumbaIcon className="h-6 sm:h-8 fill-current" />
        <LogoIcon className="absolute left-0 top-0 -ml-6 sm:-ml-8 h-6 sm:h-8 fill-current" />
      </Link>
      <span className="hidden p-name p-org fn org">{site.siteMetadata.title}</span>
    </span>
  )
}
