import { graphql, useStaticQuery } from 'gatsby'
import { ldJsonHelper } from './ldJson'

// https://developers.google.com/search/docs/advanced/structured-data/logo
export const LdJsonLogo = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  const siteUrl = site.siteMetadata.siteUrl || ''
  const ldJsonImage = siteUrl + '/img/tumbalogo.png'

  const jdJsonLogoContent = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: site.siteMetadata.title || 'Tumba Solutions',
    slogan: site.siteMetadata.description || 'Pure passion for technology and product growth.',
    sameAs: [
      siteUrl,
      'https://clutch.co/profile/tumba-solutions',
      'https://github.com/TumbaSolutions/',
      'https://www.linkedin.com/company/tumba-solutions-ltd-/',
      'https://www.facebook.com/tumba.solutions',
      'https://www.instagram.com/tumba.solutions',
      'https://twitter.com/TumbaSolutions/',
      'https://www.youtube.com/channel/UCce0nOFno-f5mfEhG9OwiHQ',
    ],
    url: siteUrl,
    logo: ldJsonImage,
  }

  return ldJsonHelper(jdJsonLogoContent)
}

LdJsonLogo.propTypes = {}
